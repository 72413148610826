<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Apply ready-to-go journey
      </v-card-title>

      <v-card-text>      
        <v-alert v-if="!canApply" type="warning" class="mt-8">You can only apply a ready-to-go journeys when the leaderboard has a goal defined. Please set the leaderboard goal first and then come back to apply the template.</v-alert>
        <v-alert v-if="hasTemplateApplied" type="warning" class="mt-8">This leaderboard is already based on a template.</v-alert>

        <v-alert type="info" class="mt-8">This is a BETA feature. Templates are FREE DURING BETA! Feedback is welcome through our contact form :)</v-alert>

        <v-radio-group v-model="selectedTemplateId" label="Select the template">
          <v-radio v-for="template in templates" :key="template.id"
            :value="template.id"
            :label="`${template.name}: ${template.description}`"
            />
        </v-radio-group>
      </v-card-text>

      <v-card-text>
        <!-- <v-btn color="primary" large @click="save">Save</v-btn> -->
        <v-btn :disabled="!canApply" color="primary" @click="save">Apply</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import assetsService from "@/services/assetsService";
import { EventBus } from '@/plugins/eventbus.js';

import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RelaySlots",
  components: {
    Header,
    RaceSelector,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      race: null,
      templates: [],
      selectedTemplateId: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.templates = (await assetsService.getMapTemplates()).data.data;
      console.log('race meta', this.race.meta);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    async save() {
      const response = (await eventManagerService.applyTemplateToRace(this.event.id, this.race.id, this.selectedTemplateId)).data;
      this.$helpers.toastResponse(this, response, 'Successfully applied template.');
      if (response.status == 'OK') {
        await this.eventUtil.refresh();
        this.$router.push({ name: 'eventmanagerRace', params: { id: this.event.id, raceId: this.race.id }});
      }
    },


  },
  computed: {
    hasTemplateApplied() {
      return this.race && this.race.meta != null && this.race.meta.some(x => x => x.key == 'template.id');
    },
    canApply() {
      return !this.hasTemplateApplied
        && this.race 
        && this.$helpers.canApplyTemplateToLeaderboard(this.event, this.race)
        && this.selectedTemplateId;
    },

    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },

    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Template', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

